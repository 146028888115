import {
	GET_ALL_SUBJECTS,
	GET_ALL_SUBJECTS_SUCCESS,
	GET_ALL_SUBJECTS_FAILURE,
	GET_CHAPTERS,
	GET_CHAPTERS_SUCCESS,
	GET_CHAPTERS_FAILURE,
	GET_TOPICS,
	GET_TOPICS_SUCCESS,
	GET_TOPICS_FAILURE,
	UPLOAD_NOTES,
	UPLOAD_NOTES_SUCCESS,
	UPLOAD_NOTES_FAILURE,
	ERASE_NOTES_UPLOAD_ERROR,
	// CHANGE_COURSEID,
	CHANGE_SUBJECTID,
	CHANGE_CHAPTERID,
	CHANGE_TOPICID,
	CHANGE_FILE_TYPE,
	CHANGE_SEQUENCE_NUMBER,
	CLEAR_ALL_TYPE_FIELDS,
} from "../actions/type";

const initialState = {
	loading: false,
	error: "",
	data: {},
	notesUploadMessage: "",
	courseData: [],
	subjectData: [],
	chapterData: [],
	topicData: [],
	// courseId: "",
	subjectId: "",
	chapterId: "",
	topicId: "",
	sequenceNumber: "",
	fileTypeSelected: "PDF",
	fileTypesAllowed: [
		{ fileTypeId: 1, fileType: "PDF" },
		{ fileTypeId: 2, fileType: "IMAGE" },
	],
};

const notesUploadReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_ALL_SUBJECTS:
			return {
				...state,
				loading: true,
				subjectData: [],
			};
		case GET_ALL_SUBJECTS_SUCCESS:
			return {
				...state,
				loading: false,
				subjectData: payload,
			};
		case GET_ALL_SUBJECTS_FAILURE:
			return {
				...state,
				loading: false,
			};
		case GET_CHAPTERS:
			return {
				...state,
				//loading: true,
				chaptersData: [],
			};
		case GET_CHAPTERS_SUCCESS:
			return {
				...state,
				//loading: false,
				chaptersData: payload,
			};
		case GET_CHAPTERS_FAILURE:
			return {
				...state,
				//loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case GET_TOPICS:
			return {
				...state,
				//loading: true,
				topicsData: [],
			};
		case GET_TOPICS_SUCCESS:
			return {
				...state,
				//loading: false,
				topicsData: payload,
			};
		case GET_TOPICS_FAILURE:
			return {
				...state,
				//loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case UPLOAD_NOTES:
			return {
				...state,
				loading: true,
				notesUploadMessage: "",
			};
		case UPLOAD_NOTES_SUCCESS:
			return {
				...state,
				loading: false,
				notesUploadMessage: payload,
			};
		case UPLOAD_NOTES_FAILURE:
			return {
				...state,
				loading: false,
				error: payload || "Something went wrong, Please try again later",
			};
		case ERASE_NOTES_UPLOAD_ERROR:
			return {
				...state,
				loading: false,
				error: "",
				notesUploadMessage: "",
			};

		case CHANGE_SUBJECTID:
			return {
				...state,
				subjectId: payload,
			};
		case CHANGE_CHAPTERID:
			return {
				...state,
				chapterId: payload,
			};
		case CHANGE_TOPICID:
			return {
				...state,
				topicId: payload,
			};
		case CHANGE_FILE_TYPE:
			return {
				...state,
				fileTypeSelected: payload,
			};
		case CHANGE_SEQUENCE_NUMBER:
			return {
				...state,
				sequenceNumber: payload,
			};
		case CLEAR_ALL_TYPE_FIELDS:
			return {
				...state,
				subjectId: "",
				chapterId: "",
				topicId: "",
				sequenceNumber: "",
			};

		default:
			return state;
	}
};

export default notesUploadReducer;
