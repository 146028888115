const {
  RESOURCE_VALIDATE_REQUEST,
  RESOURCE_VALIDATE_REQUEST_SUCCESS,
  RESOURCE_VALIDATE_REQUEST_FAILURE,
  RESOURCE_OTP_VALIDATE,
  RESOURCE_OTP_VALIDATE_SUCCESS,
  RESOURCE_OTP_VALIDATE_FAILURE,
} = require("../actions/type");


const initialState = {
	loading: false,
	validated: false,
	error: '',
    data:{},
    finalData:{}
};

const resourceReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
        case RESOURCE_VALIDATE_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case RESOURCE_VALIDATE_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
                data:action.payload,
			};
		case RESOURCE_VALIDATE_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload || 'Please try again later',
			};
        case RESOURCE_OTP_VALIDATE:
            return {
                ...initialState,
                loading: true,
            };
        case RESOURCE_OTP_VALIDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                data:action.payload,
            };
        case RESOURCE_OTP_VALIDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload || 'Please try again later',
            };
        default:
            return state;

    }
}

export default resourceReducer;