import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {loader} from "./../../img/imageLinks"

import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  loader: {
    position: 'relative',
  },
  loaderWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: '999',
    transform: 'translate(-50%, -50%)',
    maxHeight: '8rem',
  },
  loaderChildren: {
    opacity: 0.3,
  },
}));

const Loader = ({ children, loading }) => {
  const classes = useStyle();
  return loading ? (
    <div className={classes.loader}>
      <img className={classes.loaderWrapper} src={loader} alt='loading...' />
      <div className={classes.loaderChildren}>{children}</div>
    </div>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loader;
