import React, { Fragment, useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import store from "./store";
import axios from "axios";
import { connect } from "react-redux";
import { SET_AUTH_TOKEN } from "./actions/type";
import "./style/style.css";
import "./App.css";
import { loader } from "./img/imageLinks";
import { ROUTES } from "./route";
import NoInternet from "./components/layout/NoInternet"; //dont want lazy loaded
import Navbar from "./components/layout/Navbar"; //Navbar is not lazy loaded
import UserDetailsModal from "./components/auth/UserDetailsModal";
import ContactSupport from "./components/layout/ContactSupport";
// import { setRedirection } from './actions/auth';

const NotFound = lazy(() => import("./components/layout/NotFound"));
const Footer = lazy(() => import("./components/layout/Footer"));

//export const AppURL = "http://95.216.174.93:8089";
// export const AppURL = "http://localhost:8080";
export const AppURL = "https://api.debugsbunny.com";

function App(props) {
	const { userDetailsIncompleteError, redirecting } = props;
	axios.defaults.headers.post["Content-Type"] = "application/json";
	axios.defaults.baseURL = AppURL;

	const [onlineState, setOnlineState] = useState(true);

	axios.interceptors.request.use(
		(config) => {
			//sending institudeId as headers as api was designed in that way for register student.

			//console.log("config.url", config.url);

			if (config.url.includes("/notes/upload/")) {
				config.headers["content-type"] = "multipart/form-data";
			}
			return config;
		},
		(error) => {
			Promise.reject(error);
		}
	);

	if (localStorage.dbToken) {
		axios.defaults.headers["Authorization"] = localStorage.dbToken;
	}

	useEffect(() => {
		window.addEventListener("offline", () => {
			setOnlineState(false);
		});

		window.addEventListener("online", () => {
			setOnlineState(true);
		});
	}, []);

	// useEffect(() => {
	//   redirecting && setRedirection(false);
	// }, [redirecting])

	useEffect(() => {
		if (localStorage.dbToken) {
			store.dispatch({
				type: SET_AUTH_TOKEN,
				payload: { token: localStorage.dbToken, role: localStorage.dbRole },
			});
		}
	});

	useEffect(() => {
		let userDetailsBtn = document.getElementById("userDetailsBtn");
		if (userDetailsBtn !== undefined && userDetailsIncompleteError) {
			userDetailsBtn.click();
		}
	}, [userDetailsIncompleteError]);

	const getFallbackUI = () => {
		return (
			<div className="fallBack-UI container">
				<img
					className="loaderWrapper mx-auto my-auto"
					src={loader}
					alt="loading..."
				/>
			</div>
		);
	};

	const checkInternet = () => {
		if (onlineState) {
			if (redirecting) {
				return <Redirect to="/login" />;
			} else {
				return (
					<Suspense fallback={getFallbackUI()}>
						<Switch>
							<Route
								exact
								path="/"
								render={() => {
									return <Redirect to="/login" />;
								}}
							/>
							{ROUTES.map((route) => (
								<Route
									exact={route.exact}
									path={route.path}
									component={route.component}
								/>
							))}
							<Route path="/:notfound" component={NotFound} />
						</Switch>
					</Suspense>
				);
			}
		} else {
			return <NoInternet />;
		}
	};

	return (
		<BrowserRouter>
			<Fragment>
				{window.location.pathname !== "/play-maze" && <Navbar />}
				{/*<Navbar />*/}
				<button
					style={{ display: "none" }}
					data-toggle="modal"
					data-target="#userDetailsModal"
					id="userDetailsBtn"
				/>
				<ContactSupport />
				<Suspense fallback={getFallbackUI()}>
					{userDetailsIncompleteError && <UserDetailsModal />}
				</Suspense>
				{checkInternet()}
				<Suspense fallback={<div></div>}>
					{window.location.pathname !== "/play-maze" && <Footer />}
					{/*<Footer />*/}
				</Suspense>
			</Fragment>
		</BrowserRouter>
	);
}

const mapStateToProps = (state) => ({
	userDetailsIncompleteError: state.auth.userDetailsIncompleteError,
	redirecting: state.auth.redirecting,
});

const enhance = connect(mapStateToProps, {
	// setRedirection
});

export default enhance(App);
