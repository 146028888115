export const timeSlots = [30, 45, 60];

export const instituteId = 1;

export const haveLaptopOptions = ['Yes', 'No'];

export const classOptions = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'Engineering-First Year',
  'Engineering-Second Year',
  'Engineering-Third Year',
  'Engineering-Fourth Year',
  'Engineering-Fifth Year',
];

export const mandatoryLanguageOptions = [
  'English',
  'Hindi'
];

export const languageOptions = [
  'English',
  'Hindi',
  'Marathi',
  'Punjabi',
  'Gujarati',
  'Kannada',
  'Telugu',
  'Tamil',
  'Malayalam'
];

export const languageEnum = [
  "ENG","HIN","MAR","PUN","GUJ","KAN","TEL","TAM","MAL"
]

export const gradeOptions = [
  { id: 1, label: '1st' },
  { id: 2, label: '2nd' },
  { id: 3, label: '3rd' },
  { id: 4, label: '4th' },
  { id: 5, label: '5th' },
  { id: 6, label: '6th' },
  { id: 7, label: '7th' },
  { id: 8, label: '8th' },
  { id: 9, label: '9th' },
  { id: 10, label: '10th' },
  { id: 11, label: '11th' },
  { id: 12, label: '12th' },
  { id: 13, label: 'Engineering-First Year'},
  { id: 13, label: 'Engineering-Second Year'},
  { id: 13, label: 'Engineering-Third Year'},
  { id: 13, label: 'Engineering-Fourth Year'},
  { id: 13, label: 'Engineering-Fifth Year'},
];
