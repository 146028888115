import React, {useEffect} from "react";
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles} from '@material-ui/core/styles';
import "./contactButton.css";
import {connect} from 'react-redux';
import {getContactSupport} from "../../actions/other";

const useStyles = makeStyles((theme) => ({
    contact: {
        position: 'fixed',
        bottom: '0',
        right: '0',
        zIndex: 1000,
        display: 'flex'
    },
    contactButton: {
        color: '#029d00',
        border: 'none',
        outline: 'none',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        padding:".4rem .8rem"
    },
    text: {
        fontWeight: "bold"
    }

}));

const ContactSupport = (props) => {

    const {helpData, helpError, getContactSupport,validated ,role} = props;
    const classes = useStyles();

    useEffect(() => {
        if(validated && role !== "TEACHER"){
            getContactSupport();
        }
    }, [validated,getContactSupport ,role])

    if(!validated || role === "TEACHER"){
        return '';
    }



    const getJSX = () =>{
        if(helpError.length > 0){
            return '';
        }
        return (
            <div className={classes.contact + " contact-support"}>
                <div className="row">
                    <div className="col">
                        <div className="collapse multi-collapse" id="multiCollapseContact">
                            <div className={classes.card + " card card-body"}>
                                <div>
                                    <span className={classes.text}>Name : </span> {helpData.name}
                                </div>
                                <div>
                                    <span className={classes.text}> Contact : </span>{helpData.contactNumber}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <IconButton
                    aria-label="contact support"
                    className={classes.contactButton + " contactButton"}
                    data-toggle="collapse"
                    data-target="#multiCollapseContact"
                    aria-expanded="false"
                    aria-controls="multiCollapseContact"
                >
                    <HelpIcon fontSize="large"/>
                </IconButton>
            </div>
        );
    }

    return (
        getJSX()
    );
}

const mapStateToProps = (state) => ({
    helpData: state.other.helpData,
    helpError: state.other.error,
    validated:state.auth.validated,
    role: state.auth.role,
})

export default connect(mapStateToProps, {
    getContactSupport
})(ContactSupport);

