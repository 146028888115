import { CLEAR_ALERT, SET_ALERT } from '../actions/type';

const initialState = {
  message: '',
  type: '',
};

const alertReucer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_ALERT: {
      const { message, type } = payload;
      return {
        ...state,
        message,
        type,
      };
    }
    case CLEAR_ALERT:
      return {
        state: initialState,
      };
    default:
      return state;
  }
};

export default alertReucer;
