import React, { useState, useEffect } from "react";
import "./ResourceHome.css";
import UserCheck from "./UserCheck";
import UserOTPCheck from "./UserOTPCheck";
import DownloadPage from "./DownloadPage";
import {
  validateContactNumberDownloadResource,
  validateOTPToDownloadResource,
} from "../../actions/resources.js";
import { connect } from "react-redux";
import Loader from "../layout/Loader";
import queryString from 'query-string';


const ResourceHome = (props) => {
  const {
    loading,
    validated,
    error,
    validateContactNumberDownloadResource,
    validateOTPToDownloadResource,
  } = props;

  const [queryParams, setQueryParams] = useState({ id: "", name: "" });
  const [view, setView] = useState(0);
  const [updateNumber, setUpdateNumber] = useState(false);

  const handleUpdateNumber = () => {
    setUpdateNumber(true);
  };

  useEffect(() => {
    let params = queryString.parse(props.location.search);
    if(params){
      let id = params.id;
      let name = params.name;
      setQueryParams((prevValue) => {
            return {
              ...prevValue,
              id,
              name,
            };
          });
    }
  }, [])

  const changeView = (num) => {
    console.log({ num });

    if (num == 0) {
      setView(0);
    } else if (num == 1) {
      setView(1);
    } else if (num == 2) {
      setView(2);
    }
  };

  const getView = (type) => {
    switch (type) {
      case 0:
        return (
          <UserCheck
            name={queryParams.name}
            resourceId={queryParams.id}
            changeView={changeView}
            handleUpdateNumber={handleUpdateNumber}
            updateNumber={updateNumber}
          />
        );

      case 1:
        return (
          <UserOTPCheck
            name={queryParams.name}
            resourceId={queryParams.id}
            changeView={changeView}
          />
        );

      case 2:
        return (
          <DownloadPage
            name={queryParams.name}
            resourceId={queryParams.id}
            changeView={changeView}
          />
        );

      default:
        break;
    }
  };

  return (
    //<Loader loading={loading}>
      <div className="resource-home">{getView(view)}</div>
    //</Loader>
  );
};

const mapStateToProps = (state) => ({
  loading: state.resource.loading,
  validated: state.resource.loading,
  error: state.resource.loading,
});

const enhance = connect(mapStateToProps, {
  validateContactNumberDownloadResource,
  validateOTPToDownloadResource,
});

export default enhance(ResourceHome);
