import React from 'react'
import {noInternetImage} from "./../../img/imageLinks"

const NoInternet = () => {
    return (
        <div className="no-internet container">
            <div className="row">
                <div className="col-12 no-internet-image">
                    <img src={noInternetImage} alt="noInternetImage" />
                </div>
                <div className="col-12">
                <h3>Looks like you lost your connection. <br />Please check it and try again.</h3>
                </div>
            </div>
        </div>
    )
}

export default NoInternet;
