const {
  WALLET_SUCCESS,
  REQ_WALLET,
  WALLET_FAILED,
} = require('../actions/type');

const initialState = {
  cashCredit: '',
  referralCredit: '',
  loading: false,
};

const walletReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case REQ_WALLET:
      return {
        ...state,
        loading: true,
      };
    case WALLET_SUCCESS: {
      const { cashCredit, referralCredit } = payload.data;
      return {
        ...state,
        loading: false,
        cashCredit,
        referralCredit,
      };
    }
    case WALLET_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default walletReducer;
