import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  makeStyles,
  Fade,
  TextField,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import Loader from "./Loader";
import { Alert } from "@material-ui/lab";
import { btnStyle } from "../../utils/commonStyles";
import {
  validateContactNumberDownloadResource,
  validateOTPToDownloadResource,
} from "../../actions/resources.js";
import { connect } from "react-redux";
import "./resources.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto 1rem",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 4),
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "46rem",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  field: {
    width: "20rem",
    marginTop: "2rem",
  },
  btnLink: {
    textDecoration: "none",
    color: "white",
  },
  timer: {
    paddingTop: "6px",
  },
}));

const UserOTPCheck = (props) => {
  const {
    name,
    resourceId,
    changeView,
    loading,
    validated,
    error,
    data,
    finalData,
    validateContactNumberDownloadResource,
    validateOTPToDownloadResource,
  } = props;

  const [open, setOpen] = useState(true);
  const [otp, setOtp] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [counter, setCounter] = useState(120);

  useEffect(() => {
    setCounter(120);
  }, [loading]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const classes = useStyles();
  const btn = btnStyle();

  const submitInfo = async () => {
    await validateOTPToDownloadResource({
      contactNumber,
      resourceId,
      otp,
    });
  };

  const resendOTP = () => {
    validateContactNumberDownloadResource({
      contactNumber,
      resourceId,
    });
  };

  const editPhoneNumber = () => {
    changeView(0);
  };

  useEffect(() => {
    if (data) {
      if (data.isVerified === 1) {
        //1 means verified
        changeView(2);
      }
    }
  }, [data]);

  useEffect(() => {
    setContactNumber(data.contactNumber);
  }, []);

  return (
    <Loader loading={loading}>
      <Modal open={open} className={classes.modal} disableBackdropClick={true}>
        <Fade in={open}>
          <div className={classes.paper}>
            <>
              <Typography align="center" component="h2" variant="h6">
                {`One Time Password has been sent on ${contactNumber}, Please
                enter the OTP to download the ${name}`}
              </Typography>
              {error && (
                <Box mt={2}>
                  <Alert
                    className={classes.alert}
                    variant="filled"
                    severity="error"
                  >
                    {error}
                  </Alert>
                </Box>
              )}
              <Grid md={6}>
                <div className={classes.form}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="Contact Number"
                    label="Contact Number"
                    name="contactNumber"
                    autoFocus
                    className={classes.field}
                    fullWidth
                    disabled={true}
                    value={contactNumber}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="otp"
                    label="OTP"
                    name="otp"
                    autoFocus
                    className={classes.field}
                    fullWidth
                    onChange={(event) => setOtp(event.target.value)}
                    type="number"
                  />

                  <Box display="flex" justifyContent="flex-end" m={1}>
                    <Button onClick={resendOTP} disabled={counter > 0}>
                      Resend OTP
                    </Button>
                    {counter > 0 && !loading && (
                      <Typography className={classes.timer}>
                        {new Date(counter * 1000).toISOString().substr(14, 5)}
                      </Typography>
                    )}
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    className={btn.primary}
                    disabled={otp.length != 6}
                    fullWidth
                    onClick={submitInfo}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </>
          </div>
        </Fade>
      </Modal>
    </Loader>
  );
};

const mapStateToProps = (state) => ({
  loading: state.resource.loading,
  validated: state.resource.validated,
  error: state.resource.error,
  data: state.resource.data,
});

const enhance = connect(mapStateToProps, {
  validateContactNumberDownloadResource,
  validateOTPToDownloadResource,
});

export default enhance(UserOTPCheck);
