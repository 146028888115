const {
  UPLOAD_OMR,
  UPLOAD_OMR_SUCCESS,
  UPLOAD_OMR_FAILURE,
  ERASE_OMR_ERROR,
  CLEAR_OMR_UPLOAD_DATA,
} = require("../actions/type");

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const uploadOmrReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPLOAD_OMR:
      return {
        ...state,
        loading: true,
        data: [],
        error: "",
      };

    case UPLOAD_OMR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
      };

    case UPLOAD_OMR_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload || "Something went wrong, Please try again later",
      };

    case ERASE_OMR_ERROR:
      return {
        ...state,
        error: "",
      };

    case CLEAR_OMR_UPLOAD_DATA:
      return {
        ...state,
        error: "",
        data: [],
      };

    default:
      return state;
  }
};
export default uploadOmrReducer;
