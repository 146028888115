import axios from "axios";
import { get } from "lodash";

import {
  RESOURCE_VALIDATE_REQUEST,
  RESOURCE_VALIDATE_REQUEST_SUCCESS,
  RESOURCE_VALIDATE_REQUEST_FAILURE,
  RESOURCE_OTP_VALIDATE,
  RESOURCE_OTP_VALIDATE_SUCCESS,
  RESOURCE_OTP_VALIDATE_FAILURE,
} from "./type.js";

export const validateContactNumberDownloadResource = (payload) => async (
  dispatch
) => {
  dispatch({
    type: RESOURCE_VALIDATE_REQUEST,
    payload,
  });
  try {
    const res = await axios.post("/resource/download", payload);
    dispatch({
      type: RESOURCE_VALIDATE_REQUEST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = get(err, "response.data.message");
    dispatch({
      type: RESOURCE_VALIDATE_REQUEST_FAILURE,
      payload: errors,
    });
  }
};

export const validateOTPToDownloadResource = (payload) => async (dispatch) => {
  dispatch({
    type: RESOURCE_OTP_VALIDATE,
  });
  try {
    const res = await axios.post("/resource/verifyOtp", payload);
    dispatch({
      type: RESOURCE_OTP_VALIDATE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = get(err, "response.data.message");
    dispatch({
      type: RESOURCE_OTP_VALIDATE_FAILURE,
      payload: errors,
    });
  }
};
