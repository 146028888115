const {
    UPLOAD_GAME_FAILURE,
    UPLOAD_GAME,
    UPLOAD_GAME_SUCCESS,
    GET_GAMES,
    GET_GAMES_SUCCESS,
    GET_GAMES_FAILURE
} = require("../actions/type");

const initialState = {
    uploading: false,
    uploaded: false,
    gamesData: [],
    gameLoader: false,
    gamesLoadError: false
};

const gameReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case UPLOAD_GAME: {
            return {
                ...state,
                uploading: true,
                uploaded: false
            }
        }
        case UPLOAD_GAME_SUCCESS: {
            return {
                ...state,
                uploading: false,
                uploaded: true
            }
        }
        case UPLOAD_GAME_FAILURE: {
            return {
                ...state,
                uploading: false,
                uploaded: false
            }
        }
        case GET_GAMES: {
            return {
                ...state,
                gameLoader: true,
                gamesLoadError: false
            }
        }
        case GET_GAMES_SUCCESS: {
            return {
                ...state,
                gamesData: payload.apps,
                gameLoader: false,
                gamesLoadError: false
            }
        }
        case GET_GAMES_FAILURE: {
            return {
                ...state,
                gameLoader: false,
                gamesLoadError: true
            }
        }
        default:
            return state;
    }
};

export default gameReducer;