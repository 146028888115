const {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  GET_PROFILE,
  PROFILE_SUCCESS,
  PROFILE_FAILED,
  UPDATE_PROFILE_DETAILS,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_FAILURE,
  TEACHER_GET_PROFILE,
  TEACHER_PROFILE_SUCCESS,
  TEACHER_PROFILE_FAILED,
} = require("../actions/type");

const initialState = {
  loading: false,
  data: {},
  registered: false,
  error: "",
  profile: {},
  updated: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        ...initialState,
        loading: true,
        data: action.payload,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registered: true,
        loading: false,
      };
    case REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload || "Please try again later",
      };
    case GET_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.data,
      };
    case PROFILE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROFILE_DETAILS:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };
    case UPDATE_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        updated: true,
      };
    case UPDATE_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload || "Please try again later",
      };
    case TEACHER_GET_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case TEACHER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.data,
      };
    case TEACHER_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
