import newlogo from "./acharya-logo.svg";
import hamburger from "./hamburger.svg";
import Certified from "./Certified.svg";
import certifiedMedal from "./certified-medal.svg";
import chevronOpen from "./chevronOpen.svg";
import chevronClose from "./chevronClose.svg";
import bannerImage from "./banner-img.jpg";
import uploadSmallIcon from "./upload-small-icn.svg";
import emptyAllCourses from "./emptyAllCourses.svg";
import emptyMyCourses from "./emptyMyCourses.svg";
import wavingHand from "./wavingHand.svg";
import noInternet from "./noInternet.svg";
import thankyoulogo from "./thank-you-logo.svg";
import forwardArrow from "./forwardArrow.svg";
import backwardArrow from "./backwardArrow.svg";
import previous from "./previous.svg";
import AppNameImage from "./app-name-img.svg";
import next from "./next.svg";
import quiz1 from "./quiz1.svg";
import quiz2 from "./quiz2.svg";
import quiz3 from "./quiz3.svg";
import quiz4 from "./quiz4.svg";
import quiz5 from "./quiz5.svg";
import quiz6 from "./quiz6.svg";
import quiz7 from "./quiz7.svg";
import quiz8 from "./quiz8.svg";

import changeToLandscape from "./changeToLandscape.jpg";

import uploadIcon from "./uploadIcon.png";

import upload from "./upload.png";
import remove from "./remove.png";
import file from "./file.png";
import file1 from "./file.svg";

export const quiz1Image = quiz1;
export const quiz2Image = quiz2;
export const quiz3Image = quiz3;
export const quiz4Image = quiz4;
export const quiz5Image = quiz5;
export const quiz6Image = quiz6;
export const quiz7Image = quiz7;
export const quiz8Image = quiz8;

export const previousIcon = previous;
export const nextIcon = next;
export const newLogo = newlogo;
export const AppNameImg = AppNameImage;
export const uploadSmallIcn = uploadSmallIcon;
export const bannerImg = bannerImage;
export const hamburgerIcon = hamburger;
export const certifiedIcon = Certified;
export const certifiedMedalIcon = certifiedMedal;
export const chevronCloseIcon = chevronClose;
export const chevronOpenIcon = chevronOpen;
export const emptyMyCoursesIcon = emptyMyCourses;
export const emptyAllCoursesIcon = emptyAllCourses;
export const forwardArrowIcon = forwardArrow;
export const backwardArrowIcon = backwardArrow;
export const wavingHandIcon = wavingHand;
export const noInternetImage = noInternet;
export const thankYouLogo = thankyoulogo;

export const testimonialImgVedant =
  "http://assets.debugsbunny.com/testimonials/review_1.svg";
export const testimonialImgAditya =
  "http://assets.debugsbunny.com/testimonials/review_2.svg";
export const testimonialImgSamved =
  "http://assets.debugsbunny.com/testimonials/review_3.svg";

export const dashboardBunnyNoLecture =
  "http://assets.debugsbunny.com/images/debugsbunny/dashboardBunnyNoLecture.svg";

export const dashboardIcon =
  "http://assets.debugsbunny.com/images/debugsbunny/dashboard.svg";

export const referAFriendIcon =
  "http://assets.debugsbunny.com/images/debugsbunny/referAFriend.svg";

export const loader =
  "http://assets.debugsbunny.com/images/debugsbunny/loader.gif";

export const cardImage =
  "http://assets.debugsbunny.com/images/debugsbunny/cardImage.svg";

export const playBtn =
  "http://assets.debugsbunny.com/images/debugsbunny/play-button.svg";

export const fullStarIcon =
  "http://assets.debugsbunny.com/images/debugsbunny/fullStar.svg";

export const halfStarIcon =
  "http://assets.debugsbunny.com/images/debugsbunny/halfStar.svg";

export const twoFingerIcon =
  "http://assets.debugsbunny.com/images/debugsbunny/two-finger-image.svg";

export const changeToLandscapeImage = changeToLandscape;

export const uploadImage = uploadIcon;

export const uploadIcons = upload;
export const removeIcons = remove;
export const fileIcons = file;

export const file1Image = file1;
