import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  makeStyles,
  Fade,
  TextField,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import Loader from "./Loader";
import { Alert } from "@material-ui/lab";
import { btnStyle } from "../../utils/commonStyles";
import {
  validateContactNumberDownloadResource,
  validateOTPToDownloadResource,
} from "../../actions/resources.js";
import { connect } from "react-redux";
import "./resources.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto 1rem",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2,4,3),
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "46rem",
    //margin: theme.spacing("5rem", "auto"),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  field: {
    width: "20rem",
    marginTop: "2rem",
  },
  btnLink: {
    textDecoration: "none",
    color: "white",
  },
  timer: {
    paddingTop: "6px",
  },
}));

const UserCheck = (props) => {
  const {
    name,
    resourceId,
    changeView,
    loading,
    validated,
    error,
    data,
    validateContactNumberDownloadResource,
    validateOTPToDownloadResource,
    updateNumber,
    handleUpdateNumber
  } = props;

  const [open, setOpen] = useState(true);
  const [contactNumber, setContactNumber] = useState("");

  const classes = useStyles();
  const btn = btnStyle();

  const submitInfo = async () => {
    await validateContactNumberDownloadResource({
      contactNumber,
      resourceId,
    });
  };

  useEffect(() => {
    if(loading){
      setOpen(false);
    }
    else{
      setOpen(true);
    }
  }, [loading])

  useEffect(() => {
    if(data.isVerified === 1){ //1 means verified
      changeView(2);
    }
    else if(data.isVerified === 0){ //0 means not verified
      changeView(1);
    }
  }, [data]);

  return (
    <Loader loading={loading}>
      <Modal
        open={open}
        className={classes.modal}
        disableBackdropClick={true}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <>
              <Typography align="center" component="h2" variant="h6">
                {`Verify your mobile number to download the ${name}`}
              </Typography>
              {error && (
              <Box mt={2}>
                <Alert
                  className={classes.alert}
                  variant="filled"
                  severity="error"
                >
                  {error}
                </Alert>
              </Box>
            )}
              <Grid md={12}>
                <div className={classes.form}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="Contact Number"
                    label="Contact Number"
                    name="contactNumber"
                    autoFocus
                    className={classes.field}
                    type="number"
                    fullWidth
                    onChange={(event) => setContactNumber(event.target.value)}

                  />

                  <Button
                    type="submit"
                    variant="contained"
                    className={btn.primary}
                    disabled={contactNumber.length != 10}
                    fullWidth
                    onClick={submitInfo}
                    style={{marginTop:"2rem"}}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </>
          </div>
        </Fade>
      </Modal>
    </Loader>
  );
};

const mapStateToProps = (state) => ({
  loading: state.resource.loading,
  validated: state.resource.validated,
  error: state.resource.error,
  data: state.resource.data,
});

const enhance = connect(mapStateToProps, {
  validateContactNumberDownloadResource,
  validateOTPToDownloadResource,
});

export default enhance(UserCheck);
