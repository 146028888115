const {
    GET_CONTACT_SUPPORT,
    GET_CONTACT_SUPPORT_SUCCESS,
    GET_CONTACT_SUPPORT_FAILURE
} = require("./../actions/type");

const initialState = {
    loading:false,
    helpData:{},
    error:""
}

const otherReducer = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case GET_CONTACT_SUPPORT:
            return { ...state, loading: true };

        case GET_CONTACT_SUPPORT_SUCCESS:
            return { ...state, loading: false, helpData: action.payload.data };

        case GET_CONTACT_SUPPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload || "Something went wrong, Please try again later",
            };

        default:
            return state;
    }
};

export default otherReducer;
