import axios from "axios";
import {get} from "lodash";

import {
    GET_CONTACT_SUPPORT,
    GET_CONTACT_SUPPORT_SUCCESS,
    GET_CONTACT_SUPPORT_FAILURE
} from "./type";

export const getContactSupport = (payload) => async (dispatch) => {
    dispatch({
        type: GET_CONTACT_SUPPORT,
        payload
    });
    try {
        const res = await axios.get("/student/help");

        dispatch({
            type: GET_CONTACT_SUPPORT_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        const errors = get(err, "response.data.message");
        dispatch({
            type: GET_CONTACT_SUPPORT_FAILURE,
            payload: errors,
        });
    }
};
