import { CLEAR_ALERT, SET_ALERT } from './type';

export const setAlert = (payload) => (dispatch) => {
  dispatch({
    type: SET_ALERT,
    payload,
  });

  setTimeout(() => dispatch({ type: CLEAR_ALERT }), 5000);
};
