import {
  REFERRAL_FAILED,
  REFERRAL_STATUS_FAILED,
  REFERRAL_STATUS_SUCCESS,
  REQ_REFERRAL,
  REQ_REFERRAL_STATUS,
  REWARDS_SUCCESS,
  REFERRAL_SUCCESS,
} from '../actions/type';

const initialState = {
  loading: false,
  referralStatus: [],
  statusLoading: false,
  rewards: [],
};

const referralReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQ_REFERRAL:
      return {
        ...state,
        loading: true,
      };

    case REFERRAL_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case REQ_REFERRAL_STATUS:
      return {
        ...state,
        statusLoading: true,
      };

    case REFERRAL_STATUS_SUCCESS:
      return {
        ...state,
        statusLoading: false,
        referralStatus: payload.data,
      };

    case REFERRAL_STATUS_FAILED:
      return {
        ...state,
        statusLoading: false,
        error: payload,
      };

    case REWARDS_SUCCESS:
      return {
        ...state,
        statusLoading: false,
        rewards: payload.data,
      };

    case REFERRAL_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default referralReducer;
