import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { gradeOptions, languageOptions, mandatoryLanguageOptions } from '../../constants';
import { updateUserDetails } from '../../actions/auth';
import store from "../../store";
import OutsideClickHandler from 'react-outside-click-handler';
import { UPDATE_USER_DETAILS_SUCCESS } from "../../actions/type";

const UserDetailsModal = ({ updateUserDetails, contactNumber, userDetailsIncompleteError, errorPage, userDetailsIncompleteErrorMessage }) => {
    const [kidsStandard, setKidsStandard] = useState('');
    const [emailId, setEmailId] = useState('');
    const [kidsName, setKidsName] = useState('');
    const [city, setCity] = useState('');
    const [pinCode, setPinCode] = useState(0);
    const [language1, setLanguage1] = useState('DEFAULT');
    const [language2, setLanguage2] = useState('DEFAULT');

    useEffect(() => {
        let form = document.getElementById('details-form');
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            if (form.checkValidity() === false) {
                event.stopPropagation();
            } else {
                store.dispatch({
                    type: UPDATE_USER_DETAILS_SUCCESS
                });
                setTimeout(() => {
                    window.location.href = errorPage === 'Default' ? '/dashboard' : '/subscription';
                }, 1500)
            }
        })
    });

    return (
        <div className="modal fade mt-5" aria-labelledby="userDetailsLabel" id="userDetailsModal">
            <div className="modal-dialog" role="document">
                <OutsideClickHandler
                    onOutsideClick={() => window.location.href = '/dashboard'}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title">Personal Details Update</h6><br />
                            <small className="text-danger align-right">* indicates mandatory field</small>
                        </div>
                        <div className="modal-body">
                            {renderModal()}
                        </div>
                    </div>
                </OutsideClickHandler>
            </div>
        </div>
    );

    function renderModal() {
        if (userDetailsIncompleteErrorMessage === 'STUDENT_DEMO_INCOMPLETE_DETAILS') {
            return (
                <form id="details-form" onSubmit={() => updateDetails()}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Parent's Mobile Number</label>
                        <input type="text" className="form-control" readOnly value={contactNumber} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailLabel">Email Id</label>
                        <small className="text-danger">*</small>
                        <input type="email" className="form-control" id="emailLabel" placeholder="Email Id" required onChange={(e) => setEmailId(e.currentTarget.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="kidName">Kid's Name</label>
                        <small className="text-danger">*</small>
                        <input type="text" className="form-control" id="kidName" placeholder="Kid's Name" required onChange={(e) => setKidsName(e.currentTarget.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="kidStnd">Kid's Standard</label>
                        <small className="text-danger">*</small>
                        <select className="custom-select my-1 mr-sm-2" id="kidStnd" placeholder="Standard" required onChange={(e) => setKidsStandard(e.currentTarget.value)}>
                            <option value="" selected disabled hidden>
                                Select Standard
                            </option>
                            {gradeOptions.map((opt, index) =>
                                <option value={opt.id} key={index}>{opt.label}</option>
                            )}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input type="text" className="form-control" id="city" placeholder="City" onChange={(e) => setCity(e.currentTarget.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="pincode">Pin Code</label>
                        <input type="number" className="form-control" id="pincode" placeholder="Pin Code" onChange={(e) => setPinCode(e.currentTarget.value)} />
                    </div>
                    <div className="form-group">
                        <label>Language Preference</label>
                        <small className="text-danger">*</small>
                        <select className="custom-select my-1 mr-sm-2" required id="language1" placeholder="Language" onChange={(e) => setLanguage1(e.currentTarget.value)}>
                            <option value="" selected disabled hidden>
                                Primary Language
                            </option>
                            {languageOptions.map((opt, index) =>
                                <option value={opt} key={index}>{opt}</option>
                            )}
                        </select>
                        <select className="custom-select my-1 mr-sm-2" required id="language2" placeholder="Language" onChange={(e) => setLanguage2(e.currentTarget.value)}>
                            <option value="" selected disabled hidden>
                                Secondary Language
                            </option>
                            {filterLanguageOptions().map((opt, index) =>
                                <option value={opt} key={index}>{opt}</option>
                            )}
                        </select>
                    </div>
                    {kidsStandard !== '' && kidsName !== '' && emailId !== '' && language1 !== 'DEFAULT' && language2 !== 'DEFAULT' ?
                        <button type="submit" className="btn btn-primary">Update</button> :
                        <button type="button" className="btn btn-primary" disabled>Update</button>}
                </form>
            )
        } else {
            return (
                <form id="details-form" onSubmit={() => updateDetails()}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Parent's Mobile Number</label>
                        <input type="text" className="form-control" readOnly value={contactNumber} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailLabel">Email Id</label>
                        <small className="text-danger">*</small>
                        <input type="email" className="form-control" id="emailLabel" placeholder="Email Id" required onChange={(e) => setEmailId(e.currentTarget.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="kidName">Kid's Name</label>
                        <small className="text-danger">*</small>
                        <input type="text" className="form-control" id="kidName" placeholder="Kid's Name" required onChange={(e) => setKidsName(e.currentTarget.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="kidStnd">Kid's Standard</label>
                        <small className="text-danger">*</small>
                        <select className="custom-select my-1 mr-sm-2" id="kidStnd" placeholder="Standard" required onChange={(e) => setKidsStandard(e.currentTarget.value)}>
                            <option value="" selected disabled hidden>
                                Select Standard
                                    </option>
                            {gradeOptions.map((opt, index) =>
                                <option value={opt.id} key={index}>{opt.label}</option>
                            )}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input type="text" className="form-control" id="city" placeholder="City" onChange={(e) => setCity(e.currentTarget.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="pincode">Pin Code</label>
                        <input type="number" className="form-control" id="pincode" placeholder="Pin Code" onChange={(e) => setPinCode(e.currentTarget.value)} />
                    </div>
                    <div className="form-group">
                        <label>Language Preference</label>
                        <select className="custom-select my-1 mr-sm-2" id="language1" placeholder="Language" onChange={(e) => setLanguage1(e.currentTarget.value)}>
                            <option value="" selected disabled hidden>
                                Primary Language
                                    </option>
                            {languageOptions.map((opt, index) =>
                                <option value={opt} key={index}>{opt}</option>
                            )}
                        </select>
                        <select className="custom-select my-1 mr-sm-2" id="language2" placeholder="Language" onChange={(e) => setLanguage2(e.currentTarget.value)}>
                            <option value="" selected disabled hidden>
                                Secondary Language(Optional)
                                    </option>
                            {languageOptions.map((opt, index) =>
                                <option value={opt} key={index}>{opt}</option>
                            )}
                        </select>
                    </div>
                    {kidsStandard !== '' && kidsName !== '' && emailId !== '' ?
                        <button type="submit" className="btn btn-primary">Update</button> :
                        <button type="button" className="btn btn-primary" disabled>Update</button>}
                </form>
            )
        }
    }

    function filterLanguageOptions() {
        if (mandatoryLanguageOptions.indexOf(language1) === -1) {
            return mandatoryLanguageOptions
        }
        else {
            return languageOptions.filter((el) => el !== language1)
        }
    }

    function updateDetails() {
        if (userDetailsIncompleteErrorMessage === 'STUDENT_DEMO_INCOMPLETE_DETAILS' && kidsStandard !== '' && kidsName !== '' && emailId !== ''
            && language1 !== 'DEFAULT' && language2 !== 'DEFAULT') {
            updateUserDetails({
                emailId: emailId,
                name: kidsName,
                standard: kidsStandard,
                city: city,
                language1: language1 !== 'DEFAULT' ? language1.slice(0, 3).toUpperCase() : 'DEFAULT',
                language2: language2 !== 'DEFAULT' ? language2.slice(0, 3).toUpperCase() : 'DEFAULT',
                pinCode: pinCode,
                lectureType: 'DEMO'
            })
            let btn = document.getElementById('details-close');
            !userDetailsIncompleteError && btn.click();
        }
        else if (kidsStandard !== '' && kidsName !== '' && emailId !== '') {
            updateUserDetails({
                emailId: emailId,
                name: kidsName,
                standard: kidsStandard,
                city: city,
                language1: language1 !== 'DEFAULT' ? language1.slice(0, 3).toUpperCase() : 'DEFAULT',
                language2: language2 !== 'DEFAULT' ? language2.slice(0, 3).toUpperCase() : 'DEFAULT',
                pinCode: pinCode,
                lectureType: 'NORMAL'
            })
            let btn = document.getElementById('details-close');
            !userDetailsIncompleteError && btn.click();
        }
    }
};

const mapStateToProps = (state) => ({
    contactNumber: state.user.profile.contactNumber,
    userDetailsIncompleteError: state.auth.userDetailsIncompleteError,
    errorPage: state.auth.errorPage ,
    userDetailsIncompleteErrorMessage: state.auth.userDetailsIncompleteErrorMessage
});

const enhance = connect(mapStateToProps, {
    updateUserDetails
});

export default enhance(UserDetailsModal);