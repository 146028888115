import axios from "axios";
import { get } from "lodash";
import { setAlert } from "./alert.js";
import {
  GET_SEGMENT_COURSE,
  GET_SEGMENT_COURSE_SUCCESS,
  GET_SEGMENT_COURSE_FAILED,
  GET_SUBSCRIBED_COURSES,
  GET_SUBSCRIBED_COURSES_SUCCESS,
  GET_SUBSCRIBED_COURSES_FAILED,
  GET_ALL_APPLICABLE_COURSES,
  GET_ALL_APPLICABLE_COURSES_SUCCESS,
  GET_ALL_APPLICABLE_COURSES_FAILED,
  GET_BATCHES_FOR_TEACHER,
  GET_BATCHES_FOR_TEACHER_SUCCESS,
  GET_BATCHES_FOR_TEACHER_FAILURE,
  TEACHER_BATCH_ENROLL,
  TEACHER_BATCH_ENROLL_FAILURE,
  TEACHER_BATCH_ENROLL_SUCCESS,
  USER_DETAILS_INCOMPLETE,
  GET_APPLICABLE_COURSES_DATA,
  GET_APPLICABLE_COURSES_DATA_SUCCESS,
  GET_APPLICABLE_COURSES_DATA_FAILED,
  VALIDATE_COUPON_CODE,
  VALIDATE_COUPON_CODE_SUCCESS,
  VALIDATE_COUPON_CODE_FAILED
} from "./type";
import { instituteId } from '../constants';

export const getSegmentWiseCourse = (payload) => async (dispatch) => {
  dispatch({
    type: GET_SEGMENT_COURSE,
  });
  try {
    const res = await axios.get("/course/segment/" + payload);
    dispatch({
      type: GET_SEGMENT_COURSE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = get(err, "response.data.message");
    dispatch({
      type: GET_SEGMENT_COURSE_FAILED,
      payload: errors,
    });
  }
};

export const getCourseHistory = (payload) => async (dispatch) => {
  dispatch({
    type: GET_SUBSCRIBED_COURSES,
  });
  try {
    const res = await axios.get("/student/course");
    dispatch({
      type: GET_SUBSCRIBED_COURSES_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = get(err, "response.data.message");
    dispatch({
      type: GET_SUBSCRIBED_COURSES_FAILED,
      payload: errors,
    });
  }
};

export const getAllApplicableCourses = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_APPLICABLE_COURSES,
  });

  try {
    const res = await axios.get("/student/course/all", {
      headers: {
        instituteId: instituteId
      }
    });
    dispatch({
      type: GET_ALL_APPLICABLE_COURSES_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    const errors = get(err, "response.data.message");
    if (get(err, "response.data.errorMessage") === "STUDENT_INCOMPLETE_DETAILS") {
      dispatch({
        type: USER_DETAILS_INCOMPLETE,
        payload: {
          errors: errors,
          page: 'Subscription'
        }
      });
    }
    dispatch({
      type: GET_ALL_APPLICABLE_COURSES_FAILED,
      payload: errors
    });
  }
}


export const getApplicableCoursesData = (payload) => async (dispatch) => {
  dispatch({
    type: GET_APPLICABLE_COURSES_DATA,
  });
  try {
    const res = await axios.get("/student/course/enroll?courseId=" + payload);
    dispatch({
      type: GET_APPLICABLE_COURSES_DATA_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    const errors = get(err, "response.data.message");
    dispatch({
      type: GET_APPLICABLE_COURSES_DATA_FAILED,
      payload: errors
    });
  }
}


export const validateCouponCode = (payload) => async (dispatch) => {
  dispatch({
    type: VALIDATE_COUPON_CODE,
  });
  try {
    const res = await axios.get(`/student/course/validate?couponCode=${payload.couponCode}&courseID=${payload.courseID}`);
    dispatch({
      type: VALIDATE_COUPON_CODE_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    const errors = get(err, "response.data.errorMessage");
    dispatch({
      type: VALIDATE_COUPON_CODE_FAILED,
      payload: errors
    });
  }
}


export const getBatchesForTeachers = () => async (dispatch) => {
  dispatch({
    type: GET_BATCHES_FOR_TEACHER,
  });

  try {
    const res = await axios.get("/teacher/batch/all");
    dispatch({
      type: GET_BATCHES_FOR_TEACHER_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    const errors = get(err, "response.data.message");
    dispatch({
      type: GET_BATCHES_FOR_TEACHER_FAILURE,
      payload: errors
    });
  }
}

export const enrollTeacherInBatch = (payload) => async (dispatch) => {
  dispatch({
    type: TEACHER_BATCH_ENROLL
  });
  try {
    const res = await axios.post("/teacher/batch/enroll", { batchScheduleId: payload });
    dispatch({
      type: TEACHER_BATCH_ENROLL_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    const errors = get(err, "response.data.errorMessage");
    dispatch({
      type: TEACHER_BATCH_ENROLL_FAILURE,
      payload: errors
    });
    dispatch(
      setAlert({ type: "error", message: errors || "Something went wrong" })
    );
  }
}