const {
  INITIALISE_PAYMENT_STARTED,
  INITIALISE_PAYMENT_SUCCESS,
  INITIALISE_PAYMENT_FAILED,
  SAVE_PAYMENT_RESPONSE,
  GET_PAYMENT_HISTORY,
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_PAYMENT_HISTORY_FAILED,
} = require("../actions/type");

const initialState = {
  loading: false,
  data: {},
  pastLectures: [],
  error: "",
  savePaymentResponseStatus: '',
  paymentHistory: [],
  historyLoader: false,
  historyError: "",
  paymentFailedError: ""
};

const paymentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INITIALISE_PAYMENT_STARTED:
      return { ...state, loading: true, paymentFailedError: '' };

    case INITIALISE_PAYMENT_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loading: false,
        data,
      };
    }
    case INITIALISE_PAYMENT_FAILED: {
      return {
        ...state,
        loading: false,
        paymentFailedError: payload.response.data.errorMessage
      };
    }
    case SAVE_PAYMENT_RESPONSE: {
      return {
        ...state,
        savePaymentResponseStatus: payload,
      };
    }
    case GET_PAYMENT_HISTORY: {
      return {
        ...state,
        historyLoader: true,
      };
    }
    case GET_PAYMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        historyLoader: false,
        paymentHistory: payload.data,
      };
    }
    case GET_PAYMENT_HISTORY_FAILED: {
      return {
        ...state,
        historyError: "Some Error Occurred",
        historyLoader: false
      };
    }
    default:
      return state;
  }
};

export default paymentReducer;
