import axios from "axios";
import { get, omit } from "lodash";
import { instituteId } from "../constants";
import {
	REGISTER_REQUEST,
	REGISTER_SUCCESS,
	REGISTER_FAILED,
	OTP_REQUEST,
	OTP_SUCCESS,
	OTP_FAILED,
	OTP_VALIDATED,
	OTP_VALIDATE,
	OTP_VALIDATION_FAILED,
	LOGIN_REQ,
	LOGIN_SUCCESS,
	LOGIN_FAILED,
	LOGOUT,
	OTP_CANCELLED,
	GET_PROFILE,
	PROFILE_SUCCESS,
	PROFILE_FAILED,
	UPDATE_USER_DETAILS,
	UPDATE_USER_DETAILS_SUCCESS,
	UPDATE_USER_DETAILS_FAILURE,
	FORGOT_PASSWORD_OTP_REQUEST,
	FORGOT_PASSWORD_OTP_SUCCESS,
	FORGOT_PASSWORD_OTP_FAILED,
	INFO_OTP_VALIDATE,
	INFO_OTP_VALIDATED,
	INFO_OTP_VALIDATION_FAILED,
	TEACHER_GET_PROFILE,
	TEACHER_PROFILE_SUCCESS,
	TEACHER_PROFILE_FAILED,
} from "./type";

export const login = (payload) => async (dispatch) => {
	dispatch({
		type: LOGIN_REQ,
	});
	const body = JSON.stringify(omit(payload, ["contactNumber"]));
	try {
		const res = await axios.post("/user/login", body);
		axios.defaults.headers["Authorization"] = res.data.token;
		dispatch({
			type: LOGIN_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const errors = get(err, "response.data.message");
		dispatch({
			type: LOGIN_FAILED,
			payload: errors,
		});
	}
};

export const requestOtp = (payload) => async (dispatch) => {
	dispatch({
		type: OTP_REQUEST,
	});
	try {
		const res = await axios.post("/student/triggerOtp", payload);
		dispatch({
			type: OTP_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const errors = get(err, "response.data.message");
		dispatch({
			type: OTP_FAILED,
			payload: errors,
		});
	}
};

export const validateOtp = (payload) => async (dispatch) => {
	dispatch({
		type: OTP_VALIDATE,
	});
	try {
		const res = await axios.post("/student/validateOtp", payload);
		axios.defaults.headers["Authorization"] = res.data.data.token;
		dispatch({
			type: OTP_VALIDATED,
			payload: res.data.data,
		});
	} catch (err) {
		const errors = get(err, "response.data.message");
		dispatch({
			type: OTP_VALIDATION_FAILED,
			payload: errors,
		});
	}
};

export const register = (payload) => async (dispatch) => {
	dispatch({
		type: REGISTER_REQUEST,
		payload,
	});

	const data = {
		...omit(payload, ["haveLaptop", "termsAndConditions"]),
	};
	const body = JSON.stringify(data);
	try {
		const res = await axios.post("/student/register/v1", body);
		dispatch({
			type: REGISTER_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const errors = get(err, "response.data.message");
		dispatch({
			type: REGISTER_FAILED,
			payload: errors,
		});
	}
};

export const getProfile = () => async (dispatch) => {
  dispatch({
    type: GET_PROFILE,
  });
  try {
    const res = await axios.get("/student");
    //axios.defaults.headers['Authorization'] = res.data.data.token;
    dispatch({
      type: PROFILE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = get(err, "response.data.message");
    dispatch({
      type: PROFILE_FAILED,
      payload: errors,
    });
  }
};

export const getTeacherProfile = () => async (dispatch) => {
	dispatch({
	  type: TEACHER_GET_PROFILE,
	});
	try {
	  const res = await axios.get("/teacher");
	  //axios.defaults.headers['Authorization'] = res.data.data.token;
	  dispatch({
		type: TEACHER_PROFILE_SUCCESS,
		payload: res.data,
	  });
	} catch (err) {
	  const errors = get(err, "response.data.message");
	  dispatch({
		type: TEACHER_PROFILE_FAILED,
		payload: errors,
	  });
	}
  };

export const logout = () => (dispatch) => {
	dispatch({ type: LOGOUT });
};

export const otpCancelled = () => (dispatch) => {
	dispatch({ type: OTP_CANCELLED });
};

export const updateUserDetails = (payload) => async (dispatch) => {
	dispatch({
		type: UPDATE_USER_DETAILS,
	});
	try {
		const res = await axios.put(
			payload.lectureType === "DEMO"
				? "/student/details/demo"
				: "/student/details",
			payload
		);
		axios.defaults.headers["Authorization"] = res.data.data.token;
		dispatch({
			type: UPDATE_USER_DETAILS_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const errors = get(err, "response.data.message");
		dispatch({
			type: UPDATE_USER_DETAILS_FAILURE,
			payload: errors,
		});
	}
};

export const forgotPasswordRequestOtp = (payload) => async (dispatch) => {
	dispatch({
		type: FORGOT_PASSWORD_OTP_REQUEST,
	});
	try {
		const res = await axios.post("/student/triggerForgotPasswordOtp", payload);
		dispatch({
			type: FORGOT_PASSWORD_OTP_SUCCESS,
			payload: res.data,
		});
	} catch (err) {
		const errors = get(err, "response.data.message");
		dispatch({
			type: FORGOT_PASSWORD_OTP_FAILED,
			payload: errors,
		});
	}
};

export const forgotPasswordUpdateWithOtpValidate = (payload) => async (dispatch) => {
	dispatch({
		type: INFO_OTP_VALIDATE,
	});
	try {
		const res = await axios.post("/student/changePassword", payload);
		axios.defaults.headers["Authorization"] = res.data.data.token;
		dispatch({
			type: INFO_OTP_VALIDATED,
			payload: res.data.data,
		});
	} catch (err) {
		const errors = get(err, "response.data.message");
		dispatch({
			type: INFO_OTP_VALIDATION_FAILED,
			payload: errors,
		});
	}
}


export const updateProfileDetails = (payload) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_DETAILS,
  });
  try {
    const res = await axios.put(payload.lectureType === 'DEMO' ? "/student/details/demo" : "/student/details", payload);
    dispatch({
      type: UPDATE_USER_DETAILS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = get(err, "response.data.message");
    dispatch({
      type: UPDATE_USER_DETAILS_FAILURE,
      payload: errors,
    });
  }
}

