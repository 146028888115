const {
  QUIZ_DATA,
  QUIZ_DATA_SUCCESS,
  QUIZ_DATA_FAILURE,
  QUIZ_USER_DATA_CHECK,
  QUIZ_USER_DATA_CHECK_SUCCESS,
  QUIZ_USER_DATA_CHECK_FAILURE,
  QUIZ_GET_USER_RESULT,
  QUIZ_GET_USER_RESULT_SUCCESS,
  QUIZ_GET_USER_RESULT_FAILURE,
  GET_PRIVATE_QUIZ_RESULT,
  GET_PRIVATE_QUIZ_RESULT_SUCCESS,
  GET_PRIVATE_QUIZ_RESULT_FAILURE,
  PRIVATE_QUIZ_START,
  PRIVATE_QUIZ_START_SUCCESS,
  PRIVATE_QUIZ_START_FAILURE,
  GET_PRIVATE_QUIZ_DATA,
  GET_PRIVATE_QUIZ_DATA_SUCCESS,
  GET_PRIVATE_QUIZ_DATA_FAILURE,
  UPDATE_PRIVATE_QUIZ_RESPONSE,
  UPDATE_PRIVATE_QUIZ_RESPONSE_SUCCESS,
  UPDATE_PRIVATE_QUIZ_RESPONSE_FAILURE,
  PRIVATE_QUIZ_SUBMIT,
  PRIVATE_QUIZ_SUBMIT_SUCCESS,
  PRIVATE_QUIZ_SUBMIT_FAILURE,
  PRIVATE_QUIZ_GET,
  PRIVATE_QUIZ_GET_SUCCESS,
  PRIVATE_QUIZ_GET_FAILURE,
  DASHBOARD_PRIVATE_QUIZ_START,
  DASHBOARD_PRIVATE_QUIZ_START_SUCCESS,
  DASHBOARD_PRIVATE_QUIZ_START_FAILURE,
  DASHBOARD_QUIZ_DATA,
  DASHBOARD_QUIZ_DATA_SUCCESS,
  DASHBOARD_QUIZ_DATA_FAILURE,
} = require("../actions/type");

const initialState = {
  loading: false,
  data: {},
  error: "",
  submitError: "",
  resultError: "",
  updateLoading: false,
  updateData: {},
  updateError: "",
  submitData: {},
};

const quizReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case QUIZ_DATA:
      return { ...state, loading: true };

    case QUIZ_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case QUIZ_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload || "Something went wrong, Please try again later",
      };

    case QUIZ_USER_DATA_CHECK:
      return { ...state, loading: true };

    case QUIZ_USER_DATA_CHECK_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case QUIZ_USER_DATA_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        submitError:
          action.payload || "Something went wrong, Please try again later",
      };

    case QUIZ_GET_USER_RESULT:
      return { ...state, loading: true };

    case QUIZ_GET_USER_RESULT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case QUIZ_GET_USER_RESULT_FAILURE: {
      return {
        ...state,
        loading: false,
        resultError:
          action.payload || "Something went wrong, Please try again later",
      };
    }

    case GET_PRIVATE_QUIZ_RESULT:
      return { ...state, loading: true };

    case GET_PRIVATE_QUIZ_RESULT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case GET_PRIVATE_QUIZ_RESULT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload || "Something went wrong, Please try again later",
      };
    }

    case PRIVATE_QUIZ_START:
      return { ...state, loading: true };

    case PRIVATE_QUIZ_START_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case PRIVATE_QUIZ_START_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload || "Something went wrong, Please try again later",
      };
    }

    case GET_PRIVATE_QUIZ_DATA:
      return { ...state, loading: true };

    case GET_PRIVATE_QUIZ_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case GET_PRIVATE_QUIZ_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload || "Something went wrong, Please try again later",
      };
    }

    case UPDATE_PRIVATE_QUIZ_RESPONSE:
      return { ...state, updateLoading: true };

    case UPDATE_PRIVATE_QUIZ_RESPONSE_SUCCESS:
      return { ...state, updateLoading: false, updateData: action.payload };

    case UPDATE_PRIVATE_QUIZ_RESPONSE_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateError:
          action.payload || "Something went wrong, Please try again later",
      };

    case PRIVATE_QUIZ_SUBMIT:
      return { ...state, loading: true };

    case PRIVATE_QUIZ_SUBMIT_SUCCESS:
      return { ...state, loading: false, submitData: action.payload };

    case PRIVATE_QUIZ_SUBMIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload || "Something went wrong, Please try again later",
      };

    case PRIVATE_QUIZ_GET:
      return { ...state, loading: true};

    case PRIVATE_QUIZ_GET_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case PRIVATE_QUIZ_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload || "Something went wrong, Please try again later",
      };

    case DASHBOARD_PRIVATE_QUIZ_START:
      return { ...state, loading: true, data: action.payload };

    case DASHBOARD_PRIVATE_QUIZ_START_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case DASHBOARD_PRIVATE_QUIZ_START_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload || "Something went wrong, Please try again later",
      };

    case DASHBOARD_QUIZ_DATA:
      return { ...state, loading: true };

    case DASHBOARD_QUIZ_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case DASHBOARD_QUIZ_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload || "Something went wrong, Please try again later",
      };
    }
    default:
      return state;
  }
};

export default quizReducer;
