const {
  GET_LECTURE,
  LECTURE_SUCCESS,
  LECTURE_FAILED,
  LECTURE_BOOK_REQ,
  LECTURE_BOOK_SUCCESS,
  LECTURE_BOOK_FAILED,
  LECTURE_SLOT_BOOK_REQ,
  LECTURE_SLOT_BOOK_SUCCESS,
  LECTURE_SLOT_BOOK_FAILED,
  GET_CODEORG_DATA_SUCCESS,
  GET_CODEORG_DATA_FAILURE,
  SAVE_LECTURE_ID
} = require('../actions/type');

const initialState = {
  loading: false,
  lectureData: {},
  error: '',
  slotBooked: [],
  slotBookedError: null,
  slots: [],
  codeOrgData: {
    userName: '',
    pass: '',
    url: ''
  },
  savedLectureId: -1
};

const lectureReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SAVE_LECTURE_ID: {
      return { ...state, savedLectureId: payload}
    }
    case GET_LECTURE:
      return { ...state, loading: true };
    case LECTURE_SUCCESS: {
      return {
        ...state,
        loading: false,
        lectureData: payload.data
      };
    }
    case LECTURE_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload || 'Something went wrong!!',
      };
    }
    case LECTURE_BOOK_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case LECTURE_BOOK_SUCCESS: {
      return {
        ...state,
        slotBooked: payload.data,
        loading: false,

      };
    }
    case LECTURE_BOOK_FAILED: {
      ;
      return {
        ...state,
        slotBookedError: payload,
        loading: false,
      };
    }

    case LECTURE_SLOT_BOOK_REQ:
      return { ...state, loading: true };

    case LECTURE_SLOT_BOOK_SUCCESS:
      const { data } = payload;
      return {
        ...state,
        loading: false,
        slots: data,
      };
    case LECTURE_SLOT_BOOK_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_CODEORG_DATA_SUCCESS: {
      return {
        ...state,
        codeOrgData: {
          url: payload.codeOrgUrl,
          userName: payload.codeOrgUserName,
          pass: payload.codeOrgPassword
        }
      }
    }
    case GET_CODEORG_DATA_FAILURE: {
      return {
        ...state,
        codeOrgData: null
      };
    }
    default:
      return state;
  }
};

export default lectureReducer;
