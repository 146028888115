import {
    SAVE_NOTES_SELECTION,
    GET_NOTES_COURSES,
    GET_NOTES_COURSES_SUCCESS,
    GET_NOTES_COURSES_FAILURE,
    GET_NOTES_CHAPTERS,
    GET_NOTES_CHAPTERS_SUCCESS,
    GET_NOTES_CHAPTERS_FAILURE,
    GET_NOTES_TOPICS,
    GET_NOTES_TOPICS_SUCCESS,
    GET_NOTES_TOPICS_FAILURE,
    GET_NOTES_SUBJECTS,
    GET_NOTES_SUBJECTS_SUCCESS,
    GET_NOTES_SUBJECTS_FAILURE,
    GET_NOTES_TOPICS_URL,
    GET_NOTES_TOPICS_URL_SUCCESS,
    GET_NOTES_TOPICS_URL_FAILURE,
    GET_RECORDED_LECTURES,
    GET_RECORDED_LECTURES_SUCCESS,
    GET_RECORDED_LECTURES_FAILURE
} from "../actions/type";

const initialState = {
    notesSelection: {
        course: {
            courseId: 0,
            courseName: '',
            subscribed: false
        },
        subject: {
            subjectId: 0,
            subjectName: ''
        },
        chapter: {
            chapterId: 0,
            chapterName: ''
        },
        topic: {
            topicId: 0,
            topicName: ''
        },
        topicUrl: {
            topicId: 0,
            topicUrl: ''
        }
    },
    notesLevel: 0,
    courseData: [],
    subjectData: [],
    chapterData: [],
    topicData: [],
    topicUrlData: [],
    notesLoader: false
};

const notesReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_NOTES_SELECTION: {
            return {
                ...state,
                notesSelection: payload.notesSelection,
                notesLevel: payload.notesLevel
            }
        }
        case GET_NOTES_CHAPTERS:
        case GET_NOTES_COURSES:
        case GET_NOTES_SUBJECTS:
        case GET_NOTES_TOPICS:
        case GET_NOTES_TOPICS_URL:
        case GET_RECORDED_LECTURES: {
            return {
                ...state,
                notesLoader: true
            }
        }        
        case GET_NOTES_COURSES_SUCCESS: {
            return {
                ...state,
                courseData: payload.data,
                notesLoader: false
            }
        }
        case GET_NOTES_SUBJECTS_SUCCESS: {
            return {
                ...state,
                subjectData: payload.data,
                notesLoader: false
            }
        }
        case GET_NOTES_CHAPTERS_SUCCESS: {
            return {
                ...state,
                chapterData: payload.data,
                notesLoader: false
            }
        }
        case GET_NOTES_TOPICS_SUCCESS: {
            return {
                ...state,
                topicData: payload.data,
                notesLoader: false
            }
        }
        case GET_NOTES_TOPICS_URL_SUCCESS:
        case GET_RECORDED_LECTURES_SUCCESS: {
            return {
                ...state,
                topicUrlData: payload.data,
                notesLoader: false
            }
        }
        case GET_NOTES_CHAPTERS_FAILURE:
        case GET_NOTES_COURSES_FAILURE:
        case GET_NOTES_SUBJECTS_FAILURE:
        case GET_NOTES_TOPICS_FAILURE:
        case GET_NOTES_TOPICS_URL_FAILURE:
        case GET_RECORDED_LECTURES_FAILURE: {
            return {
                ...state,
                notesLoader: false
            }
        }  
        default:
            return state;
    }
};

export default notesReducer;