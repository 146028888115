import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  makeStyles,
  Fade,
  TextField,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import Loader from "./Loader";
import { Alert } from "@material-ui/lab";
import { btnStyle } from "../../utils/commonStyles";
import {
  validateContactNumberDownloadResource,
  validateOTPToDownloadResource,
} from "../../actions/resources.js";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto 1rem",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 4),
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "46rem",
    //margin: theme.spacing("5rem", "auto"),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  field: {
    width: "20rem",
    marginTop: "2rem",
  },
  btnLink: {
    textDecoration: "none",
    color: "white",
  },
  timer: {
    paddingTop: "6px",
  },
}));

const DownloadPage = (props) => {
  const {
    name,
    resourceId,
    changeView,
    loading,
    validated,
    error,
    data,
    validateContactNumberDownloadResource,
    validateOTPToDownloadResource,
  } = props;

  const [open, setOpen] = useState(true);
  //   const [otp, setOtp] = useState("");
  //   const [contactNumber, setContactNumber] = useState("");

  const classes = useStyles();
  const btn = btnStyle();

  const downloadResource = () => {
    window.open(data.resourceUrl, "_self");
  };

  return (
    <Loader loading={loading}>
      <Modal open={open} className={classes.modal} disableBackdropClick={true}>
        <Fade in={open}>
          <div className={classes.paper}>
            <>
              <Typography align="center" component="h2" variant="h6">
                {`Click on the button to download ${name}`}
              </Typography>
              {error && (
                <Box mt={2}>
                  <Alert
                    className={classes.alert}
                    variant="filled"
                    severity="error"
                  >
                    {error}
                  </Alert>
                </Box>
              )}
              <Grid md={6}>
                <div className={classes.form}>
                  <Button
                    type="submit"
                    variant="contained"
                    className={btn.primary}
                    fullWidth
                    onClick={downloadResource}
                  >
                    Download
                  </Button>
                </div>
              </Grid>
            </>
          </div>
        </Fade>
      </Modal>
    </Loader>
  );
};

const mapStateToProps = (state) => ({
  loading: state.resource.loading,
  validated: state.resource.validated,
  error: state.resource.error,
  data: state.resource.data,
});

const enhance = connect(mapStateToProps, {
  validateContactNumberDownloadResource,
  validateOTPToDownloadResource,
});

export default enhance(DownloadPage);
