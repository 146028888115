const {
	OTP_REQUEST,
	OTP_SUCCESS,
	OTP_FAILED,
	OTP_VALIDATE,
	OTP_VALIDATED,
	OTP_VALIDATION_FAILED,
	OTP_CANCELLED,
	LOGIN_REQ,
	LOGIN_FAILED,
	LOGIN_SUCCESS,
	LOGOUT,
	SET_AUTH_TOKEN,
	USER_DETAILS_INCOMPLETE,
	UPDATE_USER_DETAILS_SUCCESS,
	FORGOT_PASSWORD_OTP_REQUEST,
	FORGOT_PASSWORD_OTP_SUCCESS,
	FORGOT_PASSWORD_OTP_FAILED,
	INFO_OTP_VALIDATE,
	INFO_OTP_VALIDATED,
	INFO_OTP_VALIDATION_FAILED,
	INFO_OTP_CANCELLED,
} = require('../actions/type');

const initialState = {
	loading: false,
	validated: false,
	error: '',
	validatLoading: false,
	role: 'student',
	token: '',
	otpTriggered: false,
	userDetailsIncompleteError: false,
	userDetailsIncompleteErrorMessage: '',
	errorPage: 'Default',
	checked:false,
	forgotError:''
};


const authReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case OTP_VALIDATE:
		case LOGIN_REQ:
			return {
				...initialState,
				validateLoading: true,
			};
		case OTP_REQUEST:
			return {
				...initialState,
				loading: true,
				otpTriggered: false,
			};
		case OTP_SUCCESS:
			return {
				...state,
				loading: false,
				otpTriggered: true,
			};
		case OTP_FAILED:
			return {
				...state,
				validated: false,
				loading: false,
				error: action.payload || 'Please try again later',
			};
		case OTP_CANCELLED:
			return {
				...state,
				loading: false,
				otpTriggered: false,
			};
		case OTP_VALIDATION_FAILED:
		case LOGIN_FAILED:
			return {
				...state,
				validated: false,
				validateLoading: false,
				error: action.payload || 'Please enter correct credentials',
				otpTriggered: false,
			};
		case OTP_VALIDATED:
		case LOGIN_SUCCESS:
			const { role, token } = payload;
			localStorage.setItem('dbToken', token);
			localStorage.setItem('dbRole', role);
			return {
				...state,
				validateLoading: false,
				validated: true,
				role,
				token,
				otpTriggered: false,
			};
		case LOGOUT:
			localStorage.removeItem('dbToken');
			localStorage.removeItem('dbRole');
			localStorage.removeItem('pop_status');
			return initialState;
		case SET_AUTH_TOKEN: {
			const { token, role } = payload;
			return {
				...state,
				validated: true,
				token,
				role,
			};
		}
		case USER_DETAILS_INCOMPLETE: {
			return {
				...state,
				userDetailsIncompleteError: true,
				userDetailsIncompleteErrorMessage: payload,
			};
		}
		case UPDATE_USER_DETAILS_SUCCESS: {
			return {
				...state,
				userDetailsIncompleteError: false,
			};
		}
		case FORGOT_PASSWORD_OTP_REQUEST:
			return {
				...initialState,
				loading: true,
				otpTriggered: false,
			};
		case FORGOT_PASSWORD_OTP_SUCCESS:
			return {
				...state,
				loading: false,
				otpTriggered: true,
			};
		case FORGOT_PASSWORD_OTP_FAILED:
			return {
				...state,
				validated: false,
				loading: false,
				forgotError: action.payload || 'Please try again later',
			};
		case INFO_OTP_VALIDATE:
			return {
				...initialState,
				validateLoading: true,
      		};
      case INFO_OTP_CANCELLED:
        return {
          ...state,
          loading: false,
		  otpTriggered: false,
        };
      case INFO_OTP_VALIDATION_FAILED:
        return {
          ...state,
          checked: false,
          validateLoading: false,
          error: action.payload || 'Please enter correct OTP',
          otpTriggered: false,
        };
      case INFO_OTP_VALIDATED:
        return {
          ...state,
          validateLoading: false,
          checked: true,
          otpTriggered: false,
        };
		default:
			return state;
	}

};

export default authReducer;
