const { makeStyles } = require('@material-ui/core');

export const btnStyle = makeStyles((theme) => ({
  primary: {
    backgroundColor: '#FBD741',
    textTransform: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#FBD741',
    },
    '&:disabled': {
      backgroundColor: '#e8d587',
      color: 'black',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '4px !important',
      padding: '3px 10px',
    },
  },
  secondary: {
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      margin: '4px !important',
      padding: '3px 10px',
    },
  },
  submit: {
    backgroundColor: '#FBD741',
    textTransform: 'none',
    margin: '1rem 0rem',
    color: 'black',
    '&:hover': {
      backgroundColor: '#FBD741',
    },
    '&:disabled': {
      backgroundColor: '#e8d587',
      color: 'black',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '3px 10px',
    },
  },
  large: {
    float: 'right',
    padding: `0.6rem 3rem`,
  },
  iconButton: {
    textTransform: 'none',
  },
  group: {
    marginLeft: 'auto',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export const formStyle = makeStyles((theme) => ({
  form: {
    margin: '2rem auto',
    [theme.breakpoints.up('xs')]: {
      width: '28rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '23rem',
    },
  },
}));

export const fontStyle = makeStyles(() => ({
  architect: {
    // fontFamily: 'Architects Daughter',
    fontFamily: 'DM Sans',
  },
  cursive: {
    //fontFamily: 'Cedarville Cursive',
    fontFamily: 'DM Sans',
    fontWeight: 'bold',
  },
}));
