const {
  GET_SEGMENT_COURSE,
  GET_SEGMENT_COURSE_SUCCESS,
  GET_SEGMENT_COURSE_FAILED,
  GET_SUBSCRIBED_COURSES,
  GET_SUBSCRIBED_COURSES_SUCCESS,
  GET_SUBSCRIBED_COURSES_FAILED,
  GET_ALL_APPLICABLE_COURSES,
  GET_ALL_APPLICABLE_COURSES_SUCCESS,
  GET_ALL_APPLICABLE_COURSES_FAILED,
  GET_BATCHES_FOR_TEACHER_SUCCESS,
  GET_BATCHES_FOR_TEACHER,
  GET_BATCHES_FOR_TEACHER_FAILURE,
  TEACHER_BATCH_ENROLL_SUCCESS,
  GET_APPLICABLE_COURSES_DATA,
  GET_APPLICABLE_COURSES_DATA_SUCCESS,
  GET_APPLICABLE_COURSES_DATA_FAILED,
  VALIDATE_COUPON_CODE,
  VALIDATE_COUPON_CODE_SUCCESS,
  VALIDATE_COUPON_CODE_FAILED,
} = require("../actions/type");

const initialState = {
  loading: false,
  data: {},
  pastLectures: [],
  error: "",
  subLoading: false,
  subscribedCourses: [],
  subscribedError: "",
  teacherAllBatches: [],
  teacherAllBatchesLoading: false,
  enrollmentSuccess: false,
  userDetailsIncompleteError: false,
  checkoutData: {},
  couponData: {}
};

const courseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SEGMENT_COURSE:
      return { ...state, loading: true };

    case GET_SEGMENT_COURSE_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loading: false,
        data,
      };
    }
    case GET_SEGMENT_COURSE_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_SUBSCRIBED_COURSES: {
      return {
        ...state,
        subLoading: true,
      };
    }
    case GET_SUBSCRIBED_COURSES_SUCCESS: {
      return {
        ...state,
        subLoading: false,
        subscribedCourses: payload.data,
      };
    }
    case GET_SUBSCRIBED_COURSES_FAILED: {
      return {
        ...state,
        subLoading: false,
        subscribedError: "Some error occurred",
      };
    }
    case GET_ALL_APPLICABLE_COURSES: {
      return {
        ...state,
        subLoading: true,
        userDetailsIncompleteError: false
      };
    }
    case GET_ALL_APPLICABLE_COURSES_SUCCESS: {
      return {
        ...state,
        subLoading: false,
        data: payload.data,
        userDetailsIncompleteError: false
      };
    }
    case GET_ALL_APPLICABLE_COURSES_FAILED: {
      return {
        ...state,
        subLoading: false,
        allCoursesError: "Some error occurred",
        userDetailsIncompleteError: true
      };
    }
    case GET_BATCHES_FOR_TEACHER: {
      return {
        ...state, teacherAllBatchesLoading: true
      }
    }
    case GET_BATCHES_FOR_TEACHER_SUCCESS: {
      return {
        ...state,
        teacherAllBatches: payload.data,
        teacherAllBatchesLoading: false
      }
    }
    case GET_BATCHES_FOR_TEACHER_FAILURE: {
      return {
        ...state, teacherAllBatchesLoading: false
      }
    }
    case TEACHER_BATCH_ENROLL_SUCCESS: {
      return {
        ...state, enrollmentSuccess: true
      }
    }
    case GET_APPLICABLE_COURSES_DATA: {
      return {
        ...state,
        subLoading: true,
        checkoutData: {},
        error: "",
        couponData: {},
      };
    }
    case GET_APPLICABLE_COURSES_DATA_SUCCESS: {
      return {
        ...state,
        subLoading: false,
        checkoutData: payload.data,
      };
    }
    case GET_APPLICABLE_COURSES_DATA_FAILED: {
      return {
        ...state,
        subLoading: false,
        error: payload || 'Please try again later',
      };
    }
    case VALIDATE_COUPON_CODE: {
      return {
        ...state,
        subLoading: true,
      };
    }
    case VALIDATE_COUPON_CODE_SUCCESS: {
      return {
        ...state,
        subLoading: false,
        couponData: payload.data,
        error: "",
      };
    }
    case VALIDATE_COUPON_CODE_FAILED: {
      return {
        ...state,
        subLoading: false,
        error: payload || 'Please try again later',
        couponData: {},
      };
    }
    default:
      return state;
  }
};

export default courseReducer;